import React from "react";
// import { set } from "react-hook-form";

import Header from "../../components/Header/Header";
import "./Q3.css";

function Q3() {
  const [number] = React.useState(Math.round(Math.random() * 100));
  const [tentativa, setTentativa] = React.useState(0);
  const [entrada, setEntrada] = React.useState(0);
  const [resposta, setResposta] = React.useState("Adivinhe o número sorteado!");
  const [finish, setFinish] = React.useState(false);

  const handleChangeEntrada = (e) => {
    e.preventDefault();
    setEntrada(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function numberMore(params) {
    // setTentativa(tentativa + 1);
    compareEntrada();
  }

  // const randomNumber = (e) => {
  //   // e.preventDefault();
  //   var sorte;
  //   sorte = Math.round(Math.random() * 100) + 1;
  //   // console.log(sorte);
  //   setNumber(sorte);
  // };

  const compareEntrada = (e) => {
    // eslint-disable-next-line eqeqeq
    if (entrada == number) {
      setResposta("Você acertou!");
      setFinish(true);
    } else if (entrada < number) {
      setResposta("O número sorteado é maior...");
      setTentativa(tentativa + 1);
      setFinish(false);
    } else if (entrada > number) {
      setResposta("O número sorteado é menor...");
      setTentativa(tentativa + 1);
      setFinish(false);
    }
  };

  // numberMore();
  // compareEntrada();

  console.log(number);
  // console.log(finish);

  return (
    <div className="main__">
      <Header />
      <div className="container__random__number">
        <div className="content__container__q3">
          <div className="title__q3">
            <h1>Questão 3</h1>
            <br />
            <p>Adivinhe o número sorteado</p>
          </div>

          <form onSubmit={handleSubmit} className="form__q3">
            <label>
              <h2>Escolha um número entre 0 e 100</h2>
            </label>
            <br />
            <label className="tentativas__q3">
              <h2>Total de Tentativas: </h2>
              <span>{tentativa}</span>
            </label>
            <label>
              <input
                type="number"
                required
                className="inp__q3"
                placeholder="0"
                onChange={handleChangeEntrada}
              />
            </label>
            <input
              type="submit"
              value="ENVIAR"
              className="btn__q3"
              onClick={numberMore}
            />
          </form>
          <div className="result__q3">
            <span>
              <h2>{resposta}</h2>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q3;
