import React from "react";
import { Link } from "react-router-dom";

import "./Menu.css";

function Menu() {
  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="header">
            <h1>Lista de Exercícios</h1>
            <p>Adriel de Oliveira Gama</p>
          </div>

          <div className="links">
            <Link to="/q1">
              <div className="modal div1">IMC</div>
            </Link>

            <Link to="/q2">
              <div className="modal div2">Conta Restaurante</div>
            </Link>

            <Link to="/q3">
              <div className="modal div3">Zero a Cem</div>
            </Link>

            <Link to="/q4">
              <div className="modal div4">Zero a Cem (Tentativas)</div>
            </Link>

            <Link to="/q5">
              <div className="modal div5">Dados do Funcionário</div>
            </Link>

            <Link to="/q6">
              <div className="modal div6">Quadrado Mágico</div>
            </Link>

            <div className="div7"></div>
            <Link to="/q7">
              <div className="modal div8">Jogo da Velha</div>
            </Link>
            <div className="div7"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
