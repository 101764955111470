import React from "react";
import { Link } from "react-router-dom";

//import Home from "../../pages/Home/Home";

import "./Header.css";

function Header() {
  return (
    <div>
      <div className="nav">
        <Link to="/">
          <h1>Home</h1>
        </Link>
        <ul className="container__nav">
          <li>
            <Link to="/q1">Question 1</Link>
          </li>
          <li>
            <Link to="/q2">Question 2</Link>
          </li>
          <li>
            <Link to="/q3">Question 3</Link>
          </li>
          <li>
            <Link to="/q4">Question 4</Link>
          </li>
          <li>
            <Link to="/q5">Question 5</Link>
          </li>
          <li>
            <Link to="/q6">Question 6</Link>
          </li>
          <li>
            <Link to="/q7">Question 7</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
