import React from "react";

import Header from "../../components/Header/Header";

import "./Q2.css";

function Q2() {
  const [pagantes, setPagantes] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [tip, setTip] = React.useState(0);

  const [general, setGeneral] = React.useState(0);
  const [single, setSingle] = React.useState(0);
  // const [discount, setDiscount] = React.useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restaurantCalc = React.useCallback(() => {
    var tipPercentage = parseFloat(tip);
    var totalAmount = parseFloat(total);
    var singleAmount = parseFloat(single);

    var tipResult = 0;
    var totalResult = 0;
    var totalEachResult = 0;

    if (isNaN(totalAmount)) {
      totalAmount = 0.0;
    }
    if (isNaN(singleAmount)) {
      singleAmount = 0.0;
    }

    tipResult = (tipPercentage / 100) * total;
    totalResult = tipResult + totalAmount;
    totalEachResult = totalResult / pagantes;

    if (tip === 0) {
      setGeneral(Math.fround(totalAmount));
      setSingle(Math.fround(totalAmount / pagantes));
    } else {
      // eslint-disable-next-line no-unused-expressions
      setGeneral(Math.fround(totalResult));
      setSingle(Math.fround(totalEachResult));
    }
  });

  React.useEffect(() => {
    restaurantCalc();
  }, [restaurantCalc, tip]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChangePagantes = (e) => {
    e.preventDefault();
    setPagantes(e.target.value);
  };
  const handleChangeTotal = (e) => {
    e.preventDefault();
    setTotal(e.target.value);
  };
  const handleChangeTip = (e) => {
    e.preventDefault();
    setTip(e.target.value);
  };

  return (
    <div className="main__">
      <Header />

      <div className="container__conta">
        <div className="content__container">
          <div className="title__q2">
            <h1>Questão 2</h1>
            <p>Conta Restaurante</p>
          </div>
          <form onSubmit={handleSubmit} className="form__q2">
            <label>
              <h2>Total pagantes:</h2>
              <input
                type="number"
                required
                placeholder="Digite a quantidade de pagantes"
                onChange={handleChangePagantes}
                className="inp"
              />
            </label>
            <label>
              <h2>Total gasto:</h2>
              <div className="label__rfe">
                <h1>R$</h1>
                <input
                  type="number"
                  required
                  placeholder="Digite o total gasto"
                  onChange={handleChangeTotal}
                  className="inp__left__content"
                />
              </div>
            </label>
            {/* <hr /> */}
            <label>
              <h2>Gorjeta:</h2>
              <div className="label__rfe">
                <input
                  type="number"
                  // required
                  placeholder="Digite porcentagem da gorjeta"
                  onChange={handleChangeTip}
                  className="inp__left__content"
                />
                <h1>- %</h1>
              </div>
            </label>
            {/* <input
              type="submit"
              value="SEND"
              className="btn__restaurant"
              onClick={restaurantCalc}
            /> */}
          </form>
          <div className="result__q2">
            <hr />
            <div className="total__result">
              <h1>Total geral:</h1>
              <span>R$ {general}</span>
            </div>
            <div className="total__result">
              <h2>Total por pessoa:</h2>
              <span>R$ {single}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q2;
