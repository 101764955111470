import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from "react";

import Home from "./pages/Home/Home";
import Q1 from "./pages/Q1/Q1";
import Q2 from "./pages/Q2/Q2";
import Q3 from "./pages/Q3/Q3";
import Q4 from "./pages/Q4/Q4";
import Q5 from "./pages/Q5/Q5";
import Q6 from "./pages/Q6/Q6";
import Q7 from "./pages/Q7/Q7";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/q1" exact component={Q1} />
        <Route path="/q2" exact component={Q2} />
        <Route path="/q3" exact component={Q3} />
        <Route path="/q4" exact component={Q4} />
        <Route path="/q5" exact component={Q5} />
        <Route path="/q6" exact component={Q6} />
        <Route path="/q7" exact component={Q7} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
