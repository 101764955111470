import React from "react";

import Header from "../../components/Header/Header";

function Q5() {
  return (
    <div className="main__">
      <Header />
    </div>
  );
}

export default Q5;
