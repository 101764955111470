import React from "react";

// import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";

import "./Home.css";

function Home() {
  return (
    <div className="main__">
      {/* <Header /> */}
      <Menu />
    </div>
  );
}

export default Home;
