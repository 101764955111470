import React from "react";

import Header from "../../components/Header/Header";

import "./Q1.css";

// centralizar o resultado

function Q1() {
  const [peso, setPeso] = React.useState(0);
  const [altura, setAltura] = React.useState(0);
  const [imc, setImc] = React.useState(0);
  const [result, setResult] = React.useState("");

  const imcCalc = () => {
    const altM = altura / 100;
    setImc((peso / (altM * altM)).toFixed(2));
  };

  //console.log(imc);

  const handleSubmit = (e) => {
    e.preventDefault();
    var calc = imc;

    if (calc < 25) {
      setResult("PESO NORMAL");
    } else if (calc >= 25 && calc <= 30) {
      setResult("SOBREPESO");
    } else if (calc > 30) {
      setResult("OBESIDADE");
    }
  };

  const handleChangeAlt = (e) => {
    e.preventDefault();
    setAltura(e.target.value);
  };

  const handleChangeKg = (e) => {
    e.preventDefault();
    setPeso(e.target.value);
  };

  return (
    <div className="main__">
      <Header />
      <div className="container">
        <div className="content">
          <div className="header__imc">
            <h1>Questão 1</h1>
            <p>IMC</p>
            <div className="result">
              <h2>Resultado: </h2>
              <span>{result}</span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="formulario__imc">
            <label>
              <h2>Altura</h2>
              <input
                type="number"
                required
                placeholder="Digite aqui sua Altura"
                onChange={handleChangeAlt}
                className="inp"
              />
            </label>

            <label>
              <h2>Peso</h2>
              <input
                type="number"
                required
                placeholder="Digite aqui seu Peso"
                onChange={handleChangeKg}
                className="inp"
              />
            </label>
            <input
              type="submit"
              value="SEND"
              className="btn__imc"
              onClick={imcCalc}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Q1;
